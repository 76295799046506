"use client";

import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import moment from "moment";

import { useTheme } from "@/components/providers/ThemeProvider";

export default function EpaperHeader() {
  const [isClient, setIsClient] = useState(false);

  const { darkMode } = useTheme();
  const pathName = usePathname();
  const isDetailPage = pathName.split("/").length === 5;

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <div className="border-b">
      <div className={" " + (isDetailPage ? " px-5 " : " container ")}>
        <div className="flex items-center justify-between py-3">
          <div className="flex items-end gap-3">
            <a href="/">
              <img
                className="w-[240px] h-[45px] lg:w-[280px] lg:h-[50px] object-cover"
                alt="Deshabhimani"
                src={!darkMode ? "/deshabhimanilogo.svg" : "/deshabhimanilogo-white.svg"}
              />
            </a>
            <span className="block bg-[#FFD325] rounded-full font-[600] text-[12px] py-[1px] px-3">
              E-PAPER
            </span>
          </div>
          {pathName === "/epaper" && isClient && (
            <div className="w-[170px] hidden md:block dark:text-white">
              <div className="text-[50px] float-left font-[300] mr-2 leading-[50px]">
                {moment(new Date()).format("DD")}
              </div>
              <span className="block mt-[9px] text-[13px]">
                {moment(new Date()).format("MMMM")}
              </span>
              <span className="block text-[13px]">{moment(new Date()).format("dddd")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
