"use client";

import { usePathname } from "next/navigation";

export default function EpaperFooter() {
  const pathName = usePathname();

  return (
    <div
      className={
        "bg-[#131311] " + (pathName.split("/").length === 5 ? " mb-[50px] sm:mb-0 " : " mb-0 ")
      }
    >
      <div className="container flex flex-col items-center justify-between gap-2 py-3 md:flex-row">
        <span className="text-white text-[13px] order-2 md:order-1">
          Copyrights © {new Date().getFullYear()}. All Right Reserved.
        </span>
        <div className="flex items-center order-1 gap-1 md:order-2">
          <span className="text-white text-[13px]">Powered By</span>
          <a
            href="https://faircodetech.com/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit Faircode infotech"
          >
            <img
              src="/images/fc-logo.webp"
              alt="Faircode infotech"
              className="w-[80px] h-fll"
              loading="lazy"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
